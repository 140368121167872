<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <!-- <div class="y-inputField r-mr-auto">
                <div class="y-inputPlace y-hasIcon-right">
                    <div class="y-select-hasIcon">
                        <select class="y-select" v-model="status">
                            <option class="y-option" value="Online">Online</option>
                            <option class="y-option" value="Offline">Offline</option>
                        </select>
                        <div class="y-select-icon">
                            <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <ButtonCustom
                class="r-mr-auto"
                @click="updateAllStatus()"
            >
                Update Status
            </ButtonCustom> -->

            <ButtonCustom
                class="r-ml-auto"
                @click="openModal()"
            >
                Tambah Data
            </ButtonCustom>
        </div>

        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        >
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <!-- <template #updated_at="props">
                {{convertDateTime(props.row.updated_at)}}
            </template> -->

            <template #updated_by="props">
                {{props.row.updated_by}}
            </template>

            <template #image="props">
                <img class="r-isExpand r-ht-230-max r-wd-600-max" :src="`${promo_url}${props.row.file_path}`">
            </template>

            <template #isi="props">
                <pre v-html="props.row.isi || '-'"></pre>
            </template>
            
            <!-- <template #status="props">
                <ButtonCustom
                    :type="promoStatus(props.row.status).type"
                    :disabled="statusLoading != null"
                    @click.stop="updateStatus(props.row)"
                >
                    <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-if="statusLoading == props.row.id" />
                    <span v-else>
                        {{promoStatus(props.row.status).label}}
                    </span>
                </ButtonCustom>
            </template> -->

            <template #action="props">
                <div class="r-menu">
                    <img class="r-is-clickable" :src="require('@/assets/icons/others/more.svg')" @click.prevent="popup = curNumber(props.index)">
                    <div class="r-menu-items" v-if="popup == curNumber(props.index)" @mouseleave="popup = -1">
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="setData(props.row)">Ubah</div>
                        <div class="r-menu-item y-red-text r-center-flex"  @click.prevent="openDeleteModal(props.row)">Hapus</div>
                    </div>
                </div>
            </template>
        </TableCustom>

        <InputModal 
            v-if="modal == 'inputModal'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        ></InputModal>

        <DeleteModal
            v-if="modal == 'deleteModal'" 
            @resetData="resetData"
            @getData="getData"
        >
        </DeleteModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import InputModal from '@/components/Promo/InputModal'
import DeleteModal from '@/components/Promo/DeleteModal'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        InputModal,
        DeleteModal
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'promo/getData',
            request: 'promo/getRequest',
            promoStatus: 'promo/getPromoStatus',
            item: 'promo/getItem',
        }),

    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            // {
            //     label: 'Diupdate Pada',
            //     isSortable: true,
            //     fieldName: 'updated_at',
            //     class: ''
            // },
            {
                label: 'Diupdate Oleh',
                isSortable: true,
                fieldName: 'updated_by',
                class: ''
            },
            {
                label: 'Promo Image',
                isSortable: false,
                fieldName: 'image',
                class: ''
            },
            {
                label: 'Isi',
                isSortable: false,
                fieldName: 'isi',
                class: ''
            },
            // {
            //     label: 'Status',
            //     isSortable: false,
            //     fieldName: 'status',
            //     class: ''
            // },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: 'Tambah',
        statusLoading: null,
        status: 'Online',
    }),
    methods: {
        ...mapActions ({
            resetItem: 'promo/resetItem',
            resetRequest: 'promo/resetRequest',
            setItem: 'promo/setItem',
            getPromos: 'promo/fetchData',
            updateStatusPromo: 'promo/updateStatus',
            updateAllStatusPromo: 'promo/updateAllStatus',
            resetFileData: 'promo/resetFileData',
            setFileData: 'promo/setFileData',
        }),
        openModal(type = 'Tambah') {
            this.modal = 'inputModal'
            this.inputType = type
        },
        setData(data) {
            const item = this.itemFormat(data)
            this.setItem(item)
            this.openModal('Ubah')
        },
        openDeleteModal(data) {
            const item = this.itemFormat(data)
            this.setItem(item)
            this.modal = 'deleteModal'
        },
        itemFormat(data){
            const fileData = {
                url: `${this.promo_url}${data.file_path}`,
                name: data.file_name,
                size: data.file_size,
                isImage: data.mime_type.split('/')[0] == 'image',
                isVideo: data.mime_type.split('/')[0] == 'video',
                file: null
            }
            this.setFileData(fileData)
            const item = {
                id: data.id,
                isi: data.isi
            }
            return item
        },
        resetData() {
            this.modal = null
            this.statusLoading = null
            this.resetItem()
            this.resetRequest()
            this.resetFileData()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getPromos()
            this.fetchData = false
            const response = this.getResponse('promo')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Promo',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Promo')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },
        async updateStatus(item) {
            this.statusLoading = item.id
            this.loading = true
            await this.updateStatusPromo(item)
            this.loading = false
            const response = this.getResponse('promo')
            this.showSnackbar({
                type: response.status == 1 ? 'success': 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
        },
        async updateAllStatus() {
            this.loading = true
            this.item.status = this.status
            await this.updateAllStatusPromo(this.item)
            this.loading = false
            const response = this.getResponse('promo')
            this.showSnackbar({
                type: response.status == 1 ? 'success': 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
        }
    }
}
</script>