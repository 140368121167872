<template>
    <Modal label="Data Promo">

        <UploadFile
            label="Image Promo"
            :fileData="fileData"
            :error="error.image"
            :errorMsg="errorMsg.image"
            @setFileData="onSetFileData"
            @resetError="resetError('image')"
            @setError="setError($event)"
            :accept="accept"
        >

        </UploadFile>

        <Tiptap
            v-model="item.isi" 
            :error="error.isi"
            :errorMsg="errorMsg.isi"
            label="Isi"
            ref="tiptap"
        >
        </Tiptap>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
import UploadFile from '@/components/Helper/UploadFile'
import Tiptap from '@/components/Helper/Tiptap'

export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
        UploadFile,
        Tiptap
    },
    mounted() {
        this.loadPromos = true
        this.setTiptapContent(this.item.isi)
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'promo/getItem',
            fileData: 'promo/getFileData',
        })
    },
    data: () => ({
        loadPromos: false,
        accept: 'image/*',
        error: {
            isi: null,
            image: null
        },
        errorMsg: {
            isi: 'Tidak boleh kosong',
            image: 'Tidak boleh kosong'
        },
        loading: false,
    }),
    methods: {
        ...mapActions({
            store: 'promo/store',
            update: 'promo/update',
            setFileData: 'promo/setFileData',
        }),
        resetData() {
            this.$emit('resetData')
        },
        onSetFileData(data){
            this.setFileData(data)
        },
        async validation(){
            const field = [
                'isi',
            ]
            return await this.validate(field,this.item)
        },
        prepareData(data){
            const formData = new FormData()
            formData.append('isi', data.isi)
            if(this.fileData.file) formData.append('image',this.fileData.file)
            return formData
        },
        setTiptapContent(value){
            this.$refs.tiptap.setData(value)
        },
        async sendData() {
            const errorcount = await this.validation()
            if (errorcount === 0) {
                const payload = this.prepareData(this.item)
                this.loading = true
                if (this.inputType == 'Tambah') {
                    await this.store(payload)
                } else {
                    await this.update({payload, id: this.item.id})
                }
                this.loading = false
                const response = this.getResponse('promo')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        }
    }
}
</script>