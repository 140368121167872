<template>
    <div class="y-inputField">
        <div class="y-labelText">
            {{label}}
        </div>
        <div class="r-guidance-tiptap">
            <div class="r-attributes-tiptap r-center-flex">
                <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                    <div class="tiptap r-center-flex">
                        <div class="r-dropdown r-center-flex" @click.prevent="dropdown = !dropdown">
                            <p style="margin-right: auto">Text Style</p>
                            <img :src="require('@/assets/icons/others/dropdown-arrow.svg')" alt="">
                            <div class="r-dropdown-items" :style=" dropdown? '' : 'display: none'">
                                <div class="r-dropdown-item r-center-flex" @click="commands.heading({ level: 1 })">Heading 1</div>
                                <div class="r-dropdown-item r-center-flex" @click="commands.heading({ level: 2 })">Heading 2</div>
                                <div class="r-dropdown-item r-center-flex" @click="commands.heading({ level: 3 })">Heading 3</div>
                                <div class="r-dropdown-item r-center-flex" @click="commands.paragraph">Paragraph</div>
                            </div>
                        </div>
                        <button
                        class="tiptap__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                        >
                        <img :src="require('@/assets/icons/tiptap/bold.svg')">
                        </button>

                        <button
                        class="tiptap__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                        >
                        <img :src="require('@/assets/icons/tiptap/italic.svg')">
                        </button>

                        <button
                        class="tiptap__button"
                        :class="{ 'is-active': isActive.strike() }"
                        @click="commands.strike"
                        >
                        <strike>S</strike>
                        <!-- <img :src="require('@/assets/icons/tiptap/bold.svg')"> -->
                        </button>

                        <button
                        class="tiptap__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                        >
                        <u>U</u>
                        <!-- <img :src="require('@/assets/icons/tiptap/bold.svg')"> -->
                        </button>

                        <button
                        class="tiptap__button"
                        :class="{ 'is-active': isActive.bullet_list() }"
                        @click="commands.bullet_list"
                        >
                        <img :src="require('@/assets/icons/tiptap/bullet.svg')">
                        </button>

                        <button
                        class="tiptap__button"
                        :class="{ 'is-active': isActive.ordered_list() }"
                        @click="commands.ordered_list"
                        >
                        <img :src="require('@/assets/icons/tiptap/list.svg')">
                        </button>

                        <button
                        class="tiptap__button"
                        :class="{ 'is-active': isActive.blockquote() }"
                        @click="commands.blockquote"
                        >
                        <img :src="require('@/assets/icons/tiptap/quote.svg')">
                        </button>
                        <!-- <button class="r-upload tiptap__button">
                        <input class="r-upload-file" type="file" id="file" ref="file" v-on:change="handleFileUpload(commands.image)" accept="image/x-png,image/jpeg"/>
                        <img :src="require('@/assets/icons/tiptap/image.svg')">
                        </button> -->

                        <button
                        class="tiptap__button"
                        @click="commands.undo"
                        >
                        <img :src="require('@/assets/icons/tiptap/undo.svg')">
                        </button>

                        <button
                        class="tiptap__button"
                        @click="commands.redo"
                        >
                        <img :src="require('@/assets/icons/tiptap/redo.svg')">
                        </button>
                    </div>
                </editor-menu-bar>
            </div>
            <div class="r-input-titap y-inputField r-isRelative">
                <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                    <div
                        class="menububble"
                        :class="{ 'is-active': menu.isActive }"
                        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                    >

                        <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                        <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                        <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
                            <img :src="require('@/assets/icons/others/close-outline-white.svg')" alt="">
                        </button>
                        </form>

                        <template v-else>
                        <button
                            class="menububble__button"
                            @click="showLinkMenu(getMarkAttrs('link'))"
                            :class="{ 'is-active': isActive.link() }"
                        >
                            <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                        </button>
                        </template>

                    </div>
                </editor-menu-bubble>
                <editor-content class="editor__content" :editor="editor"/>
                <div class="y-errorText y-red-text" :style="error ? 'opacity: 1 !important;': ''" v-if="withError">
                    {{errorMsg}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
  Placeholder
} from 'tiptap-extensions'
import link from '@/mixins/link'

export default {
    mixins: [link],
    props: {
        value: {
            type: String,
            default: null
        },
        error: {
            default: false
        },
        errorMsg: {
            default: 'Tidak Boleh Kosong'
        },
        withError: {
            default: true
        },
        label: {
            default: null
        }
    },
    components: {
        EditorContent,
        EditorMenuBar
    },
    mounted(){
        this.editor.on('update', ({ getHTML }) => {
            // get new content on update
            this.inputData(getHTML())
        })
    },
    data: () => ({
        dropdown: false,
        editor: new Editor({
            extensions: [
            new Image(),
            new History(),
            new Blockquote(),
            new BulletList(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Link(),
            new Bold(),
            new Italic(),
            new Strike(),
            new Underline(),
            new Placeholder({
                emptyEditorClass: 'is-editor-empty',
                emptyNodeClass: 'is-empty',
                emptyNodeText: 'Masukkan text anda disini . .',
                showOnlyWhenEditable: true,
                showOnlyCurrent: true,
            }),
            ],
        })
    }),
    beforeDestroy() {
        this.editor.destroy()
    },
    methods: {
        inputData(content){
            this.$emit('input',content)
        },
        setData(value){
            this.editor.setContent(value)
        }
    }
}
</script>