import { EditorMenuBubble } from 'tiptap'

export default {
    components: {
        EditorMenuBubble
    },
    data: () => ({
        linkUrl: null,
        linkMenuIsActive: false,
    }),
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(command, url) {
            command({ href: url })
            this.hideLinkMenu()
        },
    },
}